import type { PlatformEvent, EditorSDK } from '@wix/platform-editor-sdk';
import { TranslationFunction } from 'i18next';
import { EditorScriptFlowAPI } from 'yoshi-flow-editor-runtime/build/esm/flow-api/EditorScript';
import Experiments from '@wix/wix-experiments';
import { withMembersArea } from '@wix/members-area-integration-kit';
import { MA_APP_IDS } from '@wix/members-area-app-definitions';

function log(...str: string[]) {
  console.log('[Restaurants editor script]', ...str);
}

let t: TranslationFunction;
let appToken: string;
let experiments: Experiments;

const ORDERS_APP_ID = '13e8d036-5516-6104-b456-c8466db39542';
const IFRAME_WIDGET_ID = '13e8d047-31b9-9c1f-4f89-48ba9430f838';
const OOI_WIDGET_ID = '96254d42-7323-40cb-a7cb-b7c242019728';

const editorReadyImpl = async (
  editorSDK: EditorSDK,
  appDefinitionId: any,
  platformOptions: any,
  flowAPI: EditorScriptFlowAPI,
) => {
  experiments = await flowAPI.getExperiments();
  t = flowAPI.translations.t;
  appToken = appDefinitionId;

  if (!experiments.enabled('specs.restaurants.olo-client-migration')) {
    log('Experiment is disabled.');
    return;
  }

  log('Fetching all components.');

  const allComponents = await editorSDK.components.getAllComponents(appDefinitionId);

  log('Fetching components data.');

  const allComponentStructures = await editorSDK.components.get(appDefinitionId, {
    componentRefs: allComponents,
    properties: ['data'],
  });

  log('Searching for online-orders iframe.');

  const iframeComponent = allComponentStructures.find(
    (cs: any) => cs?.data?.appDefinitionId === ORDERS_APP_ID && cs?.data?.widgetId === IFRAME_WIDGET_ID,
  );

  if (iframeComponent && iframeComponent.componentRef) {
    log(`found iframe component! (id = "${iframeComponent.componentRef.id}")`);

    log('replacing data.widgetId to OOI...');

    try {
      await editorSDK.document.transactions.runAndWaitForApproval('', () => {
        log('transaction started');
        return editorSDK.document.components.data.update('', {
          componentRef: iframeComponent.componentRef,
          data: { widgetId: OOI_WIDGET_ID },
        });
      });
    } catch (e) {
      log('encountered an error!', e.message);
      return;
    }

    log('done!');
  } else {
    log('iframe component was not found.');
  }
};

const OPEN_ORDER_SETTING = 'open_order_setting';
const OPEN_MANAGE_MENUS = 'open_manage_menus';
const OPEN_ORDERS = 'open_orders';

const getAppManifestImpl = () => {
  let appDescriptor;
  if (!experiments.enabled('specs.restaurants.olo-client-manage-apps')) {
    log('Manage My Apps experiment is disabled.');
  } else {
    appDescriptor = {
      mainActions: [
        {
          title: t('online_ordering_editor_app_manager_restaurants_main_CTA'),
          actionId: OPEN_MANAGE_MENUS,
          icon: 'appManager_settingsAction',
        },
      ],
      customActions: [
        {
          title: t('online_ordering_editor_app_manager_quick_action_1'),
          actionId: OPEN_MANAGE_MENUS,
          icon: 'appManager_relatedAppsAction',
          type: 'dashboard',
        },
        {
          title: t('online_ordering_editor_app_manager_quick_action_2'),
          actionId: OPEN_ORDER_SETTING,
          icon: 'appManager_settingsAction',
          type: 'dashboard',
        },
        {
          title: t('online_ordering_editor_app_manager_quick_action_3'),
          actionId: OPEN_ORDERS,
          icon: 'appManager_pagesAction',
          type: 'dashboard',
        },
      ],
      defaultActions: {
        upgrade: {
          upgradeType: 'SITE_UPGRADE',
          upgradeText: t('online_ordering_editor_app_manager_upgrade_text'),
          upgradeLinkText: t('online_ordering_editor_app_manager_upgrade_link_text'),
        },
        learnMoreKB: 'a1cedc92-d3f6-4937-a0b5-4387abb463e0',
      },
    };
  }
  return { appDescriptor };
};

interface HandlerParams<PayloadType = any> {
  sdk: EditorSDK;
  payload?: PayloadType;
  appToken: string;
}
interface AppActionPayload {
  actionId: string;
}

async function onEventImpl({ eventType, eventPayload }: PlatformEvent, editorSDK: EditorSDK) {
  switch (eventType) {
    case 'appActionClicked':
      const { actionId } = eventPayload;
      await appActionHandlers?.[actionId]?.({ sdk: editorSDK, payload: eventPayload, appToken });
      break;
    default:
      return;
  }
}

const appActionHandlers: { [key: string]: (params: HandlerParams<AppActionPayload>) => void } = {
  [OPEN_MANAGE_MENUS]: async ({ sdk, appToken: appDefId }) => {
    await sdk.editor.openDashboardPanel(appDefId, { closeOtherPanels: false, url: '/restaurants' });
    return sdk.tpa.app.refreshApp(appDefId);
  },
  [OPEN_ORDERS]: async ({ sdk, appToken: appDefinitionId }) => {
    await sdk.editor.openDashboardPanel(appDefinitionId, { closeOtherPanels: false, url: '/restaurants/orders' });
    return sdk.tpa.app.refreshApp(appDefinitionId);
  },
  [OPEN_ORDER_SETTING]: async ({ sdk, appToken: appDefinitionId }) => {
    await sdk.editor.openDashboardPanel(appDefinitionId, {
      closeOtherPanels: false,
      url: '/restaurants/ordersSettings',
    });
    return sdk.tpa.app.refreshApp(appDefinitionId);
  },
};

function handleActionImpl() {
  // Not using this function explicitly, but it must be defined
  // in order to be patched by members-area-integration-kit
}

const platformApp = withMembersArea(
  {
    editorReady: editorReadyImpl,
    getAppManifest: getAppManifestImpl,
    onEvent: onEventImpl,
    handleAction: handleActionImpl,
  },
  {
    disableADI: false,
    installAutomatically: true,
    installAppsAutomatically: true,
    membersAreaApps: [MA_APP_IDS.MY_ADDRESSES, MA_APP_IDS.MY_WALLET],
    experimentOptions: {
      name: 'specs.restaurants.olo-client-members-area',
      scope: 'restaurants',
      truthyValue: 'true',
    },
  },
);

export const editorReady = platformApp.editorReady;
export const getAppManifest = platformApp.getAppManifest;
export const onEvent = platformApp.onEvent;
export const handleAction = platformApp.handleAction;
